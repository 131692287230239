$(function () {//開頭
	var WINDOW = $(window).width();//視窗寬度
	var WINDOWH = $(window).height();//視窗高度

	//----------------gotop功能-----------------
	$(".gotop").click(function () {
		$("html,body").animate({ scrollTop: 0 }, 300);
		return false;
	});

	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) { //若目前的位置距離網頁頂端>100px
			$(".gotop").fadeIn("fast");
		} else {
			$(".gotop").stop().fadeOut("fast");
		}
	});

	//---------------------開始----------------------
	//字級控制
	$(".toplink-font").find("a").click(function(){
		$(".toplink-font").find("a").removeClass("active");
		if($(this).hasClass("js-font-sm")){
			$("body").addClass("sm");
		}else if($(this).hasClass("js-font-md")){
			$("body").removeClass("sm").removeClass("lg");
		}else if($(this).hasClass("js-font-lg")){
			$("body").addClass("lg");
		}
		$(this).addClass("active");
		return false;
	})
	//漢堡選單
	$('#navbar-all').on('hide.bs.collapse', function () {
		$('.navbar-toggler').removeClass('open');
	})
	$('#navbar-all').on('show.bs.collapse', function () {
		$('.navbar-toggler').addClass('open');
	})

	//下拉選單
	$('.dropdown-menu a.dropdown-toggle').on('click', function () {
		if (!$(this).next().hasClass('show')) {
			$(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
		}
		var $subMenu = $(this).next(".dropdown-menu");
		$subMenu.toggleClass('show');
		$(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
			$('.dropdown-submenu .show').removeClass("show");
		});
		return false;
	});
	$(".nav-link").click(function () {
		$(this).parent().siblings().find(".nav-link").removeClass("active");
		$(this).toggleClass("active");
	});
	$(".dropdown-item").click(function () {
		$(this).toggleClass("active");
	});

	//收合footer選單

	$(".footer-info-toggler").click(function () {
		$(this).toggleClass("active");
		$(".footer-menu").slideToggle(200);
	});

	//分類頁籤設定
	$(".js-tag-menu").find("a").click(function () {
		$(".js-tag-menu").find("a").removeClass("active");
		$(this).toggleClass("active");
	});

	//服務列表手機選單設定
	$(".service-list-menu-mobile").click(function () {
		$(".service-list-menu-mobile").not(this).removeClass("active");
		$(this).toggleClass("active");
	})

	//側邊選單設定
	$(".side-item-menu").click(function () {
		$(this).toggleClass("active");
	})


})//尾端	



